import { PortalName } from "@/config"

export const Variables = {
	links:{
		transparency: "",
		PMA: "",
	},
	portalName:"Prefeitura Municipal de XXXX....",
	portalSite:"www.prefeitura.es.gov.br",
	address:"",
	cityHallName: "Laranja da Terra",
	htmlTextLaw:`<p>Uma ouvidoria pública atua no diálogo entre o Cidadão e a Administração Pública, de modo que as manifestações decorrentes do exercício da cidadania provoquem a contínua melhoria dos serviços públicos prestados.</p>
		<p>Este canal é o espaço de diálogo entre o cidadão e a Administração Pública. Aqui o cidadão poderá auxiliar a Administração Pública na constante melhoria dos serviços públicos encaminhando denúncias, reclamações, elogios, sugestões, solicitações, etc.</p>
		<p>A Ouvidoria garante que os seus dados servirão para avaliar melhor as políticas públicas e propor melhorias voltadas para você ou para sua comunidade.</p>
		`
}