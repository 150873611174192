import { Component, Prop, Vue } from 'vue-property-decorator'

//wrappers
import {
	wIcon
} from '@/components/wrappers';

@Component({
	components: {
		wIcon
	}
})
export default class ECard extends Vue {
	@Prop({required: true}) icon!: string
	@Prop({required: true}) title!: string
	@Prop({default: "/"}) route!: string
	@Prop({default: true}) enabled!: boolean

	get type(){
		return this.$store.getters['ouvidoria/getType']
	}
	set type(newValue: any){
		this.$store.commit('ouvidoria/setTypeWithTitle', newValue);
	}

	goToRoute(): void {
		this.type = this.title;
		if(this.enabled){
			this.$router.push(this.route)
		}
	}

	get getColor(): string {
		if(!this.enabled){
			return 'e-disabled' 
		} return ''
	}
}
