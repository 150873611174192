import { render, staticRenderFns } from "./Estatisticacas.vue?vue&type=template&id=857b257c&scoped=true&"
import script from "./estatisticacas.ts?vue&type=script&lang=ts&"
export * from "./estatisticacas.ts?vue&type=script&lang=ts&"
import style0 from "./Estatisticacas.vue?vue&type=style&index=0&id=857b257c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "857b257c",
  null
  
)

export default component.exports